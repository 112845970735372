<!-- Modal convenio de confidencialidad -->
<button id="openModalConvenioConf" type="button" class="btn btn-info btn-lg" data-toggle="modal"
    data-target="#modalConvenioConf" [hidden]="true">
    Open The Modal Box
</button>
<div class="modal fade" id="modalConvenioConf" tabindex="-1" data-backdrop="false" data-dismiss="modal"
    data-keyboard="false" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Convenio de confidencialidad</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="logout()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="
				position: relative;
    			padding-bottom: 56.25%;
    			height: 0;
    			overflow: hidden; ">
                    <iframe src="\assets\formularios\COMPROMISO DE CONFIDENCIALIDAD.pdf#toolbar=0" style="
					position: absolute ;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;">
                    </iframe>
                </div>
                <hr />
                <form [formGroup]="convenioForm">
                    <div class="form-group">
                        <input formControlName="check" type="checkbox" required /> Acepto el convenio de
                        confidencialidad
                    </div>
                    <button style="width: 70px; font-size: 12px; float: right" (click)="submitConvenio()"
                        [disabled]="this.convenioFormSubmitted || !this.convenioForm.value.check" type="button"
                        class="btn btn-xs btn btn-info">
                        Aceptar
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>



<div class="container-fluid">
    <div class="row" id="row-home">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
            <div>
                <h2>Eventos</h2>
                <div *ngIf="eventosSociales != null; else sinCumple">
                    <ul *ngFor="let item of eventosSociales">
                        <li>{{ item.descripcion | mayusPipe }} - <strong>{{
                                item.tipo.descripcion | mayusPipe }}</strong>
                        </li>
                    </ul>
                </div>
                <ng-template #sinCumple>
                    <p style="text-align: center">No hay eventos para el d&iacute;a de hoy.</p>
                </ng-template>
            </div>
            <mat-divider id="separador"></mat-divider>
            <div>
                <h2>
                    Novedades
                    <button mat-mini-fab color="primary" *ngIf="editor" class="btn btn-primary mx-auto my-fab"
                        (click)="crearNovedad()">
                        <mat-icon>add</mat-icon>
                    </button>
                </h2>
                <div *ngIf="novedades != null; else sinCumple">
                    <ul *ngFor="let novedad of novedades">
                        <li>
                            <button mat-mini-fab color="primary" *ngIf="editor"
                                class="btn btn-primary mx-auto float-right my-fab" (click)="modificarNovedad(novedad)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <div class="div" id="cuerpo" [innerHTML]="novedad.texto | safeHTML">
                            </div>
                            <!-- -
              <small>{{ novedad.fechaPublicacion | date: 'dd/MM/yyyy' }}</small> -->
                        </li>
                        <!-- {{cumpleanos | json}} -->
                    </ul>
                </div>
                <ng-template #sinCumple>
                    <p style="text-align: center">No hay novedades para el d&iacute;a de hoy.</p>
                </ng-template>
            </div>

            <mat-divider id="separador"></mat-divider>
            <h2>Enlaces Relacionados</h2>
            <mat-list role="list" style="border: 0px solid red;">
                <mat-list-item role="listitem">
                    <img src="../../assets/images/home/enlaces-relacionados/Hospital Espanol.png"
                    alt="Hospital" class="align-middle icono-enlace-relacionado">
                    <a href="http://www.hospitalespanol.org.ar"
                        target="_blank">&nbsp;Hospital
                        Espa&ntilde;ol</a>
                </mat-list-item>
                <mat-list-item role="listitem" style="margin-top: 1vh;">
                    <img src="../../assets/images/home/enlaces-relacionados/Calendario 2024.png"
                    alt="Calendario" class="align-middle icono-enlace-relacionado">
                    <a href="{{ urlCalendario }}" target="_blank" class="link-menu">
                        Calendario 2025
                    </a>
                </mat-list-item>
                <mat-list-item role="listitem">
                    <img src="../../assets/images/home/enlaces-relacionados/Sitio de Medicos.png"
                    alt="Sitio de Medicos" class="align-middle icono-enlace-relacionado">
                    <a
                        href="http://profesionales.hospitalespanol.org.ar/he_fe/#/login" target="_blank">&nbsp;Sitio de
                        M&eacute;dicos</a></mat-list-item>
                <mat-list-item role="listitem">
                    <img src="../../assets/images/home/enlaces-relacionados/Sitio de Enfermeria.png"
                    alt="Sitio de Enfermeria" class="align-middle icono-enlace-relacionado"><a
                        href="http://enfermeros.hospitalespanol.org.ar/login" target="_blank">&nbsp;Sitio de
                        Enfermeros</a>
                </mat-list-item>
              
                <mat-list-item role="listitem">
                    <img src="../../assets/images/home/enlaces-relacionados/Pedidos - Mantenimiento.png"
                    alt="Mantenimiento" class="align-middle icono-enlace-relacionado">
                    <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSciAAMiCmr5jGeaU5MyeZSGRbP9IFiRAsqyMTX4WTmzu-SE1g/viewform"
                        target="_blank">&nbsp;Mantenimiento</a></mat-list-item>
                <!-- <mat-list-item role="listitem">
                    <mat-icon class="icon-enlaces">email</mat-icon> <a href="http://redmine/redmine/login?back_url=http%3A%2F%2Fredmine%2Fredmine%2Fprojects%2Fincidencias%2Fissues%2Fnew" target="_blank">&nbsp;Redmine</a></mat-list-item> -->
                <mat-list-item role="listitem" style="border: 0px solid blue; margin-top: 1vh;">
                    <img src="../../assets/images/home/enlaces-relacionados/Formulario - Mejoras sobre el ambito laboral.png"
                        alt="Formulario - Mejoras sobre el ambito laboral" class="align-middle icono-enlace-relacionado">
                    <a
                        href="https://docs.google.com/forms/d/1GDvWOke5L1ZiCylPs3Ks-TMUW0P_vR0NXIcScStF72w/edit"
                        target="_blank">&nbsp;Sugerencias de mejoras sobre el &aacute;mbito laboral</a>
                    </mat-list-item>
                <!-- <mat-list-item role="listitem">
                    <mat-icon class="icon-enlaces">description</mat-icon>
                    <a href="https://intranet.hospitalespanol.org.ar/thot_restfull/api/certificados/imprimir/covid19/{{usuarioLog.usuario}}" target="_blank">&nbsp;Certificado COVID-19</a>
                </mat-list-item>
                <mat-list-item role="listitem">
                    <mat-icon class="icon-enlaces">description</mat-icon> 
                    <a href="https://intranet.hospitalespanol.org.ar/thot_restfull/api/certificados/imprimir/certificado_trabajo/{{usuarioLog.usuario}}" target="_blank">&nbsp;Certificado laboral</a>
                </mat-list-item> -->
                <mat-list-item role="listitem" style="margin-top: 1vh;">
                    <img src="../../assets/images/home/enlaces-relacionados/Telefonos Internos.png"
                        alt="Telefonos internos" class="align-middle icono-enlace-relacionado">
                    <a href="{{url_telefonos_internos}}" target="_blank" class="link-menu">
                        Teléfonos internos
                    </a>
                </mat-list-item>

                <mat-list-item role="listitem" style="border: 0px solid blue; margin-top: 1vh;">
                    <img src="../../assets/images/home/enlaces-relacionados/Autogestion.png"
                        alt="Autogestion" class="align-middle icono-enlace-relacionado">
                    <a href="https://hospitalespanol.metiri.la/autogestion/#/login" target="_blank">Autogestión -
                        Empleados/as</a>
                </mat-list-item>

                <mat-list-item role="listitem" style="border: 0px solid blue; margin-top: 1vh;">
                    <img src="../../assets/images/home/enlaces-relacionados/Gestion de Personal.png"
                        alt="Gestion de personal" class="align-middle icono-enlace-relacionado">
                    <a href="https://hospitalespanol.metiri.la/metiri/loginppal.php" target="_blank">Gestión del
                        personal - Jefes/as</a>
                </mat-list-item>

                <mat-list-item>
                    <img src="../../assets/images/home/enlaces-relacionados/Campus Educativo.png"
                        class="align-middle icono-enlace-relacionado" /><a
                        href="https://campus.hospitalespanol.org.ar/login/index.php" target="_blank"> Campus
                        Educativo</a>
                </mat-list-item>
            </mat-list>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
            <app-mostrar-noticias></app-mostrar-noticias>
        </div>
    </div>
</div>