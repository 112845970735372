import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { User } from './models/user.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy {
  usserLogged: User;
  mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;

  public static get baseUrl(): string {
    if (window.location.hostname === 'localhost') {
      return (
        window.location.protocol +
        '//' +
        window.location.hostname +
        ':8080/thot_restfull/api/'
      );
    } else {
      return (
        window.location.protocol +
        '//' +
        window.location.hostname +
        '/thot_restfull/api/'
      );
    }
  }

  constructor(
    private authService: AuthenticationService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
    this.authService.currentUser.subscribe(
      (resp) => {
        this.usserLogged = resp;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  
  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
  }
}
